import shma from "./shma.mp3";
import vahavta from "./vahavta.mp3";
import vayomer from "./vayomer.mp3";
import lmaan from "./lmaan.mp3";

export const sounds: Record<string, string> = {
  shma,
  vahavta,
  vayomer,
  lmaan,
};