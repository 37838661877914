import React from 'react';
import { sounds } from './sounds';
import './styles.css';

export const ShmaPage: React.FC = () => {
  return (
    <div className="Shma">
      <h1>Sh'ma</h1>
      <div className="Shma-paragraph">
        <h2>1. Sh'ma Yisrael</h2>
        <p className="Shma-hebrew">
          <strong>שְׁמַ֖ע יִשְׂרָאֵ֑ל יְהֹוָ֥ה אֱלֹהֵ֖ינוּ יְהֹוָ֥ה ׀ אֶחָֽד׃</strong><br />
          <small>בָּרוּךְ שֵׁם כְּבוֹד מַלְכוּתוֹ לְעוֹלָם וָעֶד</small>
        </p>
        <p>
          <strong>Sh'ma Yisrael, Adonai Eloheinu, Adonai Echad!</strong><br />
          <small>Baruch shem k'vod malchuto l'olam va-ed</small>
        </p>
        <p>
          <audio controls src={sounds.shma} />
          </p>
      </div>

      <div className="Shma-paragraph">
        <h2>2. V'Ahavta</h2>
        <p className="Shma-hebrew">
          <strong>
            וְאָ֣הַבְתָּ֔ אֵ֖ת יְהֹוָ֣ה אֱלֹהֶ֑יךָ בְּכׇל־לְבָבְךָ֥ וּבְכׇל־נַפְשְׁךָ֖ וּבְכׇל־מְאֹדֶֽךָ׃<br />
            וְהָי֞וּ הַדְּבָרִ֣ים הָאֵ֗לֶּה אֲשֶׁ֨ר אָנֹכִ֧י מְצַוְּךָ֛ הַיּ֖וֹם עַל־לְבָבֶֽךָ׃<br />
            וְשִׁנַּנְתָּ֣ם לְבָנֶ֔יךָ וְדִבַּרְתָּ֖ בָּ֑ם בְּשִׁבְתְּךָ֤ בְּבֵיתֶ֙ךָ֙ וּבְלֶכְתְּךָ֣ בַדֶּ֔רֶךְ וּֽבְשׇׁכְבְּךָ֖ וּבְקוּמֶֽךָ׃<br />
            וּקְשַׁרְתָּ֥ם לְא֖וֹת עַל־יָדֶ֑ךָ וְהָי֥וּ לְטֹטָפֹ֖ת בֵּ֥ין עֵינֶֽיךָ׃<br />
            וּכְתַבְתָּ֛ם עַל־מְזֻז֥וֹת בֵּיתֶ֖ךָ וּבִשְׁעָרֶֽיךָ׃
          </strong>
        </p>
        <p>
          <small>
            V'ahavta et Adonai Elohecha b'chol l'vavcha uv'chol nafshecha uv'chol me'odecha.<br />
            V'hayu had'varim ha'eileh asher anochi m'tzavecha hayom al l'vavecha.<br />
            V'shinantam l'vanecha v'dibarta bam b'shivtecha b'veitecha uv'lechtecha vaderech, uv'shochbecha uv'kumecha.<br />
            Uk'shartam l'ot al yadecha v'hayu l'totafot bein einecha.<br />
            Uch'tavtam al m'zuzot beitecha uvisharecha.
          </small>
        </p>
        <p>
          <audio controls src={sounds.vahavta} />
        </p>
      </div>

      <div className="Shma-paragraph">
        <h2>3. Vayomer</h2>
        <p className="Shma-hebrew">
          <strong>
            וַיֹּ֥אמֶר יְהֹוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃<br />
            דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָמַרְתָּ֣ אֲלֵהֶ֔ם וְעָשׂ֨וּ לָהֶ֥ם צִיצִ֛ת עַל־כַּנְפֵ֥י בִגְדֵיהֶ֖ם לְדֹרֹתָ֑ם וְנָ֥תְנ֛וּ עַל־צִיצִ֥ת הַכָּנָ֖ף פְּתִ֥יל תְּכֵֽלֶת׃<br />
            וְהָיָ֣ה לָכֶם֮ לְצִיצִת֒ וּרְאִיתֶ֣ם אֹת֗וֹ וּזְכַרְתֶּם֙ אֶת־כׇּל־מִצְוֺ֣ת יְהֹוָ֔ה וַעֲשִׂיתֶ֖ם אֹתָ֑ם וְלֹֽא־תָת֜וּרוּ אַחֲרֵ֤י לְבַבְכֶם֙ וְאַחֲרֵ֣י עֵֽינֵיכֶ֔ם אֲשֶׁר־אַתֶּ֥ם זֹנִ֖ים אַחֲרֵיהֶֽם׃
          </strong>
        </p>
        <p>
          <small>
            Vayomer Adonai el Moshe leimor.<br />
            Daber el b'nei Yisrael v'amarta aleihem v'asu lahem tzitzit al kanfei vigdeihem l'dorotam.<br />
            V'natnu al tzitzit hak'anaf petil techelet.<br />
            V'hayah lachem l'tzitzit ur'item oto uz'chartem et kol mitzvot Adonai va'asitem otam v'lo taturu acharei l'vavchem v'acharei eineichem asher atem zonim achareihem.
          </small>
        </p>
        <p>
          <audio controls src={sounds.vayomer} />
        </p>
      </div>

      <div className="Shma-paragraph">
        <h2>4. L'maan</h2>
        <p className="Shma-hebrew">
          <strong>
            לְמַ֣עַן תִּזְכְּר֔וּ וַעֲשִׂיתֶ֖ם אֶת־כׇּל־מִצְוֺתָ֑י וִהְיִיתֶ֥ם קְדֹשִׁ֖ים לֵאלֹֽהֵיכֶֽם׃<br />
            אֲנִ֞י יְהֹוָ֣ה אֱלֹֽהֵיכֶ֗ם אֲשֶׁ֨ר הוֹצֵ֤אתִי אֶתְכֶם֙ מֵאֶ֣רֶץ מִצְרַ֔יִם לִהְי֥וֹת לָכֶ֖ם לֵאלֹהִ֑ים אֲנִ֖י יְהֹוָ֥ה אֱלֹהֵיכֶֽם׃
          </strong>
        </p>
        <p>
          <small>
            L'ma'an tizkeru va'asitem et kol mitzvotai vihyitem kedoshim leloheichem.<br />
            Ani Adonai Eloheichem asher hotzeiti etchem mei'eretz Mitzrayim lihyot lachem lelohim ani Adonai Eloheichem.
          </small>
        </p>
        <p>
          <audio controls src={sounds.lmaan} />
        </p>
      </div>
    </div>
  );
};