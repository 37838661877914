import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

export const AppBar: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="AppBar">
      <div className="AppBar-header">
        <button className="hamburger-menu" onClick={toggleDrawer}>
          &#9776; {/* Hamburger Icon */}
        </button>
        <h1>א Aleph</h1>
      </div>
      {isDrawerOpen && (
        <div className="drawer">
          <button className="close-button" onClick={toggleDrawer}>
            &times; {/* Close Icon */}
          </button>
          <Link to="/" onClick={toggleDrawer}>Home</Link>
          <Link to="/flash-cards" onClick={toggleDrawer}>Flash Cards</Link>
          <Link to="/shma" onClick={toggleDrawer}>Sh'ma</Link>
          <Link to="/torah-brachot" onClick={toggleDrawer}>Torah Blessings</Link>
          <Link to="/haftarah-brachot" onClick={toggleDrawer}>Haftarah Blessings</Link>
        </div>
      )}
    </div>
  );
};
