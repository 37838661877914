import React from 'react';
import { AppBar } from '../app-bar';
import { Outlet } from 'react-router-dom';
import './styles.css';

export const Layout: React.FC = () => {
  return (
    <div className="Layout-root">
      <AppBar />
      <div className="Layout-content">
        <Outlet />
      </div>
    </div>
  );
};