import React from 'react';
import { sounds } from './sounds';
import './styles.css';

export const HaftarahBrachotPage: React.FC = () => {
  return (
    <div className="HaftarahBrachotPage">
      <div className="HaftarahBrachotPage-root">
        <h1>Haftarah Blessings</h1>

        <div className="HaftarahBrachot-bracha">
          <h2>Before Haftarah Reading</h2>
          <p className="HaftarahBrachot-hebrew">
            <strong>
              בָּרוּךְ אַתָה יי אֵלֹהֵינוּ מֶלֶךְ הָעוֹלָם, אֲשֶׁר בָּחַר בּנְבִיאִים טוֹבִים וְרָצָה בְּדִבְרֵיהֶם הַנֶאֶמָרִים בֶּאֱמֶת. בָּרוּךְ אַתָה יי, הַבֹּחֵר בַּתּוֹרָה וּבְמשֶׁה עַבְדּוֹ וּבְיִשְׂרָאֵל עַמּוֹ וּבִנְבִיאֵי הָאֱמֶת וְהַצֶּדֶק
            </strong>
          </p>
          <p>
            <small>
              Baruch atah Adonai Eloheinu melech ha-olam asher bachar b'n'vi-im tovim v'ratza b'divrei-hem ha-ne-emarim be-emet. Baruch atah Adonai ha-bocher ba-torah uv'Moshe avdo uv'Yisrael amo uv'n'vi-ei ha-emet v'ha-tzedek.
            </small>
          </p>
          <p>
            <audio controls src={sounds.before} />
          </p>
        </div>

        <div className="HaftarahBrachot-bracha">
          <h2>After Haftarah Reading</h2>
          <div className="HaftarahBrachot-bracha-paragraph">
            <h3>Paragraph 1</h3>
            <p className="HaftarahBrachot-hebrew">
              <strong>
                בָּרוּךְ אַתָּה, ׳׳ אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם, צוּר כָּל הָעוֹלָמִים, צַדִּיק בּכָל הַדּוֹרוֹת, הָאֵל הָנֶּאֱמָן, הָאוֹמֵר וְעֹשֶׂה, הַמְדַבֵּר וּמְקַיֵּם, שֶׁכָּל דְּוָרָיו אֱמֶת וָצֶדֶק
              </strong>
            </p>
            <p>
              <small>
                Baruch atah, Adonai Eloheinu, Melech haolam, tzur kol haolamim, tzadik bechol hadorot, ha-el ha-neeman, ha-omer v'oseh, ha-medaber u'mekayem, shekol d'varav emet v'tzedek.
              </small>
            </p>
            <p>
              <audio controls src={sounds.after1} />
            </p>
          </div>

          <div className="HaftarahBrachot-bracha-paragraph">
            <h3>Paragraph 2</h3>
            <p className="HaftarahBrachot-hebrew">
              <strong>
                נֶאֱמָן אַתָּה הוּא יי אֱלֹהֵינוּ וְנֶאֱמָנִים דְּבָרֶיךָ, וְדָבָר אֶחָד מִדְּבָרֶיךָ אָחוֹר לֹא יָשׁוּב רֵיקָם, כִּי אֵל מֶלֶךְ נֶאֱמָן וְרַחַמָן אָתָּה. בָּרוּךְ אַתָּה יי, הָאֵל הַנֶּאֱמָן בְּכָל דְּבָרָיו
              </strong>
            </p>
            <p>
              <small>
                Ne-eman atah hu, Adonai Eloheinu, v'ne-emanim d'varecha, v'd'var echad mid'varecha achor lo-yashuv reikam, ki El melech ne-eman v'rachaman atah. Baruch atah Adonai, ha-el ha-ne-eman b'chol d'varav.
              </small>
            </p>
            <p>
              <audio controls src={sounds.after2} />
            </p>
          </div>

          <div className="HaftarahBrachot-bracha-paragraph">
            <h3>Paragraph 3</h3>
            <p className="HaftarahBrachot-hebrew">
              <strong>
                רַחֵם עַל צִיּוֹן כִּי הִיא בֵּית חַיֵּינוּ, וְלַעֲלוּבַת נֶפֶשׁ תּוֹשִׁיעַ בִּמְהֵרָה בְיָמֵינוּ. בָּרוּךְ אַתָּה יי, מְשַׂמֵּחַ צִיּוֹן בְּבָנֶיהָ
              </strong>
            </p>
            <p>
              <small>
                Racheim al Tzion ki hi beyt chayeinu, v'laaluvat nefesh toshia bimheirah v'yameinu. Baruch atah Adonai, m'sameiach Tzion b'vaneha.
              </small>
            </p>
            <p>
              <audio controls src={sounds.after3} />
            </p>
          </div>

          <div className="HaftarahBrachot-bracha-paragraph">
            <h3>Paragraph 4</h3>
            <p className="HaftarahBrachot-hebrew">
              <strong>
                שַׂמְחֵנוּ יי אֱלֹהֵינוּ, בְּאֵלִיָּהוּ הַנָּבִיא עַבְדֶּךָ, וּבְמַלְכוּת בֵּית דָּוִד מְשִׁיחֶךָ, בִּמְהֵרָה יָבוֹא וְיַגִּיל לִבֵּנוּ. עַל כִּסְאוֹ לֹא יֵשֵׁב זָר וְלֹא יִנְחַל עוֹד אֲחֵרִים אֶת כְּבוֹדוֹ, כִּי בְשֵׁם קָדְשְׁךָ נִשְׁבַּעְתָּ לוֹ שֶׁלֹּא יִכְבֶּה נֵרוֹ לְעוֹלָם וָעֶד. בָּרוּךְ אַתָּה יי, מָגֵן דָּוִד
              </strong>
            </p>
            <p>
              <small>
                Samcheinu, Adonai Eloheinu, b'Eliyahu hanavi avdecha, uv'malchut beyt David m'shichecha, bimheirah yavo v'yageil libeinu. Al kis'o lo yeishev zar v'lo yinchalu od acheirim et k'vodo. Ki v'sheim kodsh'cha nishbata lo shelo yichbeh neiro l'olam va-ed. Baruch atah Adonai, magein David.
              </small>
            </p>
            <p>
              <audio controls src={sounds.after4} />
            </p>
          </div>

          <div className="HaftarahBrachot-bracha-paragraph">
            <h3>Paragraph 5</h3>
            <p className="HaftarahBrachot-hebrew">
              <strong>
                עַל הַתּוֹרָה, וְעַל הָעֳבוֹדָה, וְעַל הַנְּבִיאײַים, וְעַל יוֹם הַשַׁבָּת הַזֶה, שֶׁנָתַתָּ לָנוּ יי אֱלֹחֵינוּ, לִקְדֻשָׁה וְלִמְנוּחָה, לְכָבוֹד וּלְתִפְאָרֶת.<br />
                עַל הַכׇּל יי אֱלֹהֵינוּ, אֲנַחְנוּ מוֹדִים לָךְ, וּמְבָרְכִים אוֹתָךְ, יִתְבָּרַךְ שִׁמְךָ בְּפִי כָל חַי תָּמִיד לְעוֹלָם וָעֶד. בָּרוּךְ אַתָּה יי, מְקַדֵּשׁ הַשַּׁבָּת
              </strong>
            </p>
            <p>
              <small>
                Al ha-Torah, v'al haavodah, va'al han'vi-im v'al yom haShabbat hazeh, shenatata lanu Adonai Eloheinu, likdushah v'limnuchah, l'chavod u-l'tivaret.<br />
                Al hakol Adonai Eloneinu, anachnu modim lach, u-m'var'chim otach, yitbarach shimcha b'fi chol chai tamid l'olam va-ed. Baruch atah, Adonai, m'kadeish HaShabbat.
              </small>
            </p>
            <p>
              <audio controls src={sounds.after5} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
