import React from 'react';
import { sylables } from '../../lib/hebrew';
import { sounds } from './sounds';
import './styles.css';

const keys = Object.keys(sylables);

console.log('Number of combinations:', keys.length);

const getRandomNumber = () => Math.floor(Math.random() * keys.length);

export const FlashCardsPage: React.FC = () => {
  const [showNames, setShowNames] = React.useState(true);
  const [current, setCurrent] = React.useState({
    key: keys[getRandomNumber()],
    show: false,
  });

  const [isPlaying, setIsPlaying] = React.useState(false);

  const toggleShow = React.useCallback(() => {
    setCurrent((curr) => ({
      ...curr,
      show: !curr.show,
    }));
  }, []);

  const shuffle = React.useCallback(() => {
    const index = getRandomNumber();
    setCurrent({
      key: keys[index],
      show: false,
    });
    setIsPlaying(false);
  }, []);

  const audioRef = React.useRef<HTMLAudioElement>(null);

  const handlePlayEnd = React.useCallback(() => {
    setIsPlaying(false);
  }, []);

  const soundFileName = React.useMemo(() => {
    if (!sylables[current.key].sound) return null;

    const file = sounds[sylables[current.key].sound];
    if (!file) return null;

    return file;
  }, [current.key]);

  const playSound = React.useCallback(async () => {
    if (soundFileName && audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
        return;
      } else {
        setIsPlaying(true);
        await audioRef.current.play();
      }
    }
  }, [soundFileName, isPlaying]);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        shuffle();
      } else if (e.code === 'Enter') {
        playSound();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [shuffle, playSound]);

  return (
    <div className="App">
      <h1>Hebrew Flashcards</h1>
      <p>Say the syllable out loud before clicking "show transliteration" to check the answer.</p>
      <header className="App-header">
        <div className="FlashCard" key={current.key}>
          <p className="FlashCard-hebrew" onClick={() => setShowNames(v => !v)}>
            {sylables[current.key].hebrew}
          </p>
          <p>
            {showNames ? sylables[current.key].name : '(hidden)'}
          </p>
          <div>
            <button
              className="App-link"
              onClick={toggleShow}
            >
              {current.show ? (sylables[current.key].sound || '(silent)') : 'Show transliteration' }
            </button>
          </div>
          <div>
            <audio className="FlashCard-play" controls src={soundFileName ?? ''} ref={audioRef} onEnded={handlePlayEnd} />
          </div>
        </div>
        <button
          className="App-link"
          onClick={shuffle}
        >
          Next
        </button>
      </header>
    </div>
  );
};
