import React from 'react';
import { sounds } from './sounds';
import './styles.css';

export const TorahBrachotPage: React.FC = () => {
  return (
    <div className="TorahBrachotPage-root">
      <h1>Torah Blessings</h1>
      <div className="TorahBrachot-bracha">
        <h2>Before Torah Reading</h2>
        <p className="TorahBrachot-hebrew">
          <strong>
            בָּרוּךְ אֶת יי הַמְבֹרָךְ<br />
            <small>Congregation: </small>בָּרוּךְ יי הַמְבֹרָךְ לְעוֹלָם וָעֶד<br />
            <small>You: </small>בָּרוּךְ יי הַמְבֹרָךְ לְעוֹלָם וָעֶד<br />
            בָּרוּךְ אַתָּה יי אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם אֲשֶׁר בָּחַר בָּנוּ מִכָּל הָעַמִּים וְנָתַן לָנוּ אֶת תּוֹרָתוֹ. בָּרוּךְ אַתָּה יי נוֹתֵן הַתּוֹרָה
          </strong>
        </p>
        <p>
          <small>
            Bar'chu et Adonai ha-m'vorach.<br />
            Congregation: Baruch Adonai ha-m'vorach l'olam va-ed.<br />
            You: Baruch Adonai ha-m'vorach l'olam va-ed.<br />
            Baruch atah Adonai Eloheinu melech ha-olam asher bachar banu mikol ha-amim v'natan lanu et torato. Baruch atah Adonai noten ha-torah.
          </small>
        </p>
        <audio controls src={sounds.before} />
      </div>

      <div className="TorahBrachot-bracha">
        <h2>After Torah Reading</h2>
        <p className="TorahBrachot-hebrew">
          <strong>
            בָּרוּךְ אַתָּה יי אֱלֹהֵינוּ מֶלֶךְ הָעוֹלָם אֲשֶׁר נָתַן לָנוּ תּוֹרַת אֱמֶת וְחַיֵּי עוֹלָם נָטַע בְּתוֹכֵנוּ. בָּרוּךְ אַתָּה יי נוֹתֵן הַתּוֹרָה
          </strong>
        </p>
        <p>
          <small>
            Baruch atah Adonai Eloheinu melech ha-olam asher natan lanu torat emet v'chayei olam nata b'tocheinu. Baruch atah Adonai noten ha-torah.
          </small>
        </p>
        <audio controls src={sounds.after} />
      </div>
    </div>
  );
};
