interface Info {
  name: string;
  sound: string;
  hebrew: string;
}

interface LetterInfo extends Info {
  value: number;
}

export const letters: Record<string, LetterInfo> = {
  'א': {
    name: 'aleph',
    value: 1,
    sound: '',
    hebrew: 'א',
  },
  'בּ': {
    name: 'bet',
    value: 2,
    sound: 'b',
    hebrew: 'בּ',
  },
  'ב': {
    name: 'bet (no dagesh - AKA vet)',
    value: 2,
    sound: 'v',
    hebrew: 'ב',
  },
  'ג': {
    name: 'gimel',
    value: 3,
    sound: 'g',
    hebrew: 'ג',
  },
  'ד': {
    name: 'dalet',
    value: 4,
    sound: 'd',
    hebrew: 'ד',
  },
  'ה': {
    name: 'he',
    value: 5,
    sound: 'h',
    hebrew: 'ה',
  },
  'ו': {
    name: 'vav',
    value: 6,
    sound: 'v',
    hebrew: 'ו',
  },
  'ז': {
    name: 'zayin',
    value: 7,
    sound: 'z',
    hebrew: 'ז',
  },
  'ח': {
    name: 'chet',
    value: 8,
    sound: 'ch',
    hebrew: 'ח',
  },
  'ט': {
    name: 'tet',
    value: 9,
    sound: 't',
    hebrew: 'ט',
  },
  'י': {
    name: 'yod',
    value: 10,
    sound: 'y',
    hebrew: 'י',
  },
  'כּ': {
    name: 'kaf',
    value: 20,
    sound: 'k',
    hebrew: 'כּ',
  },
  'כ': {
    name: 'kaf (no dagesh - AKA khaf)',
    value: 20,
    sound: 'kh',
    hebrew: 'כ',
  },
  'ך': {
    name: 'kaf sofit',
    value: 500,
    sound: 'k',
    hebrew: 'ך',
  },
  'ל': {
    name: 'lamed',
    value: 30,
    sound: 'l',
    hebrew: 'ל',
  },
  'מ': {
    name: 'mem',
    value: 40,
    sound: 'm',
    hebrew: 'מ',
  },
  'ם': {
    name: 'mem sofit',
    value: 600,
    sound: 'm',
    hebrew: 'ם',
  },
  'נ': {
    name: 'nun',
    value: 50,
    sound: 'n',
    hebrew: 'נ',
  },
  'ן': {
    name: 'nun sofit',
    value: 700,
    sound: 'n',
    hebrew: 'ן',
  },
  'ס': {
    name: 'samech',
    value: 60,
    sound: 's',
    hebrew: 'ס',
  },
  'ע': {
    name: 'ayin',
    value: 70,
    sound: '',
    hebrew: 'ע',
  },
  'פּ': {
    name: 'pe',
    value: 80,
    sound: 'p',
    hebrew: 'פּ',
  },
  'פ': {
    name: 'pe (no dagesh - AKA fe)',
    value: 80,
    sound: 'f',
    hebrew: 'פ',
  },
  'ף': {
    name: 'pe sofit',
    value: 800,
    sound: 'p',
    hebrew: 'ף',
  },
  'צ': {
    name: 'tzadi',
    value: 90,
    sound: 'tz',
    hebrew: 'צ',
  },
  'ץ': {
    name: 'tzadi sofit',
    value: 900,
    sound: 'tz',
    hebrew: 'ץ',
  },
  'ק': {
    name: 'kuf',
    value: 100,
    sound: 'k',
    hebrew: 'ק',
  },
  'ר': {
    name: 'resh',
    value: 200,
    sound: 'r',
    hebrew: 'ר',
  },
  'שׁ': {
    name: 'shin',
    value: 300,
    sound: 'sh',
    hebrew: 'ש',
  },
  'שׂ': {
    name: 'shin (AKA sin)',
    value: 300,
    sound: 's',
    hebrew: 'שׂ',
  },
  'ת': {
    name: 'tav',
    value: 400,
    sound: 't',
    hebrew: 'ת',
  },
}

export const nikkudot: Record<string, Info> = {
  'ַ': {
    name: 'patach',
    sound: 'a',
    hebrew: 'ַ',
  },
  'ָ': {
    name: 'kamatz',
    sound: 'a',
    hebrew: 'ָ',
  },
  'ֵ': {
    name: 'tzere',
    sound: 'e',
    hebrew: 'ֵ',
  },
  'ֶ': {
    name: 'segol',
    sound: 'e',
    hebrew: 'ֶ',
  },
  'ִ': {
    name: 'chirik',
    sound: 'i',
    hebrew: 'ִ',
  },
  'ֹ': {
    name: 'cholam',
    sound: 'o',
    hebrew: 'ֹ',
  },
  'ֻ': {
    name: 'kubutz',
    sound: 'u',
    hebrew: 'ֻ',
  },
  'ְ': {
    name: 'sheva',
    sound: '',
    hebrew: 'ְ',
  },
};

// Sylables
export const sylables: Record<string, Info> = {
  'אָ': {
    name: 'aleph with kamatz',
    sound: 'a',
    hebrew: 'אָ',
  },
  'אַ': {
    name: 'aleph with patach',
    sound: 'a',
    hebrew: 'אַ',
  },
  'אֶ': {
    name: 'aleph with segol',
    sound: 'e',
    hebrew: 'אֶ',
  },
  'אֵ': {
    name: 'aleph with tzere',
    sound: 'e',
    hebrew: 'אֵ',
  },
  'אִ': {
    name: 'aleph with chirik',
    sound: 'i',
    hebrew: 'אִ',
  },
  'אֹ': {
    name: 'aleph with cholam',
    sound: 'o',
    hebrew: 'אֹ',
  },
  'אֻ': {
    name: 'aleph with kubutz',
    sound: 'u',
    hebrew: 'אֻ',
  },
  'אְ': {
    name: 'aleph with sheva',
    sound: '',
    hebrew: 'אְ',
  },
  'בָּ': {
    name: 'bet with kamatz',
    sound: 'ba',
    hebrew: 'בָּ',
  },
  'בַּ': {
    name: 'bet with patach',
    sound: 'ba',
    hebrew: 'בַּ',
  },
  'בֶּ': {
    name: 'bet with segol',
    sound: 'be',
    hebrew: 'בֶּ',
  },
  'בֵּ': {
    name: 'bet with tzere',
    sound: 'be',
    hebrew: 'בֵּ',
  },
  'בִּ': {
    name: 'bet with chirik',
    sound: 'bi',
    hebrew: 'בִּ',
  },
  'בֹּ': {
    name: 'bet with cholam',
    sound: 'bo',
    hebrew: 'בֹּ',
  },
  'בֻּ': {
    name: 'bet with kubutz',
    sound: 'bu',
    hebrew: 'בֻּ',
  },
  'בְּ': {
    name: 'bet with sheva',
    sound: 'b',
    hebrew: 'בְּ',
  },
  // bet without dagesh
  'בָ': {
    name: 'bet (no dagesh) with kamatz',
    sound: 'va',
    hebrew: 'בָ',
  },
  'בַ': {
    name: 'bet (no dagesh) with patach',
    sound: 'va',
    hebrew: 'בַ',
  },
  'בֶ': {
    name: 'bet (no dagesh) with segol',
    sound: 've',
    hebrew: 'בֶ',
  },
  'בֵ': {
    name: 'bet (no dagesh) with tzere',
    sound: 've',
    hebrew: 'בֵ',
  },
  'בִ': {
    name: 'bet (no dagesh) with chirik',
    sound: 'vi',
    hebrew: 'בִ',
  },
  'בֹ': {
    name: 'bet (no dagesh) with cholam',
    sound: 'vo',
    hebrew: 'בֹ',
  },
  'בֻ': {
    name: 'bet (no dagesh) with kubutz',
    sound: 'vu',
    hebrew: 'בֻ',
  },
  'בְ': {
    name: 'bet (no dagesh) with sheva',
    sound: 'v',
    hebrew: 'בְ',
  },
  // gimel
  'גָ': {
    name: 'gimel with kamatz',
    sound: 'ga',
    hebrew: 'גָ',
  },
  'גַ': {
    name: 'gimel with patach',
    sound: 'ga',
    hebrew: 'גַ',
  },
  'גֶ': {
    name: 'gimel with segol',
    sound: 'ge',
    hebrew: 'גֶ',
  },
  'גֵ': {
    name: 'gimel with tzere',
    sound: 'ge',
    hebrew: 'גֵ',
  },
  'גִ': {
    name: 'gimel with chirik',
    sound: 'gi',
    hebrew: 'גִ',
  },
  'גֹ': {
    name: 'gimel with cholam',
    sound: 'go',
    hebrew: 'גֹ',
  },
  'גֻ': {
    name: 'gimel with kubutz',
    sound: 'gu',
    hebrew: 'גֻ',
  },
  'גְ': {
    name: 'gimel with sheva',
    sound: 'g',
    hebrew: 'גְ',
  },
  // dalet
  'דָ': {
    name: 'dalet with kamatz',
    sound: 'da',
    hebrew: 'דָ',
  },
  'דַ': {
    name: 'dalet with patach',
    sound: 'da',
    hebrew: 'דַ',
  },
  'דֶ': {
    name: 'dalet with segol',
    sound: 'de',
    hebrew: 'דֶ',
  },
  'דֵ': {
    name: 'dalet with tzere',
    sound: 'de',
    hebrew: 'דֵ',
  },
  'דִ': {
    name: 'dalet with chirik',
    sound: 'di',
    hebrew: 'דִ',
  },
  'דֹ': {
    name: 'dalet with cholam',
    sound: 'do',
    hebrew: 'דֹ',
  },
  'דֻ': {
    name: 'dalet with kubutz',
    sound: 'du',
    hebrew: 'דֻ',
  },
  'דְ': {
    name: 'dalet with sheva',
    sound: 'd',
    hebrew: 'דְ',
  },
  // he
  'הָ': {
    name: 'he with kamatz',
    sound: 'ha',
    hebrew: 'הָ',
  },
  'הַ': {
    name: 'he with patach',
    sound: 'ha',
    hebrew: 'הַ',
  },
  'הֶ': {
    name: 'he with segol',
    sound: 'he',
    hebrew: 'הֶ',
  },
  'הֵ': {
    name: 'he with tzere',
    sound: 'he',
    hebrew: 'הֵ',
  },
  'הִ': {
    name: 'he with chirik',
    sound: 'hi',
    hebrew: 'הִ',
  },
  'הֹ': {
    name: 'he with cholam',
    sound: 'ho',
    hebrew: 'הֹ',
  },
  'הֻ': {
    name: 'he with kubutz',
    sound: 'hu',
    hebrew: 'הֻ',
  },
  'הְ': {
    name: 'he with sheva',
    sound: 'h',
    hebrew: 'הְ',
  },
  // vav
  'וֹ': {
    name: 'vav with cholam',
    sound: 'o',
    hebrew: 'וֹ',
  },
  'וּ': {
    name: 'vav with shuruk',
    sound: 'u',
    hebrew: 'וּ',
  },
  'וָ': {
    name: 'vav with kamatz',
    sound: 'va',
    hebrew: 'וָ',
  },
  'וַ': {
    name: 'vav with patach',
    sound: 'va',
    hebrew: 'וַ',
  },
  'וֶ': {
    name: 'vav with segol',
    sound: 've',
    hebrew: 'וֶ',
  },
  'וֵ': {
    name: 'vav with tzere',
    sound: 've',
    hebrew: 'וֵ',
  },
  'וִ': {
    name: 'vav with chirik',
    sound: 'vi',
    hebrew: 'וִ',
  },
  'וֺ': {
    name: 'vav with cholam haser',
    sound: 'vo',
    hebrew: 'וֺ',
  },
  'וֻ': {
    name: 'vav with kubutz',
    sound: 'vu',
    hebrew: 'וֻ',
  },
  'וְ': {
    name: 'vav with sheva',
    sound: 'v',
    hebrew: 'וְ',
  },
  // zayin
  'זָ': {
    name: 'zayin with kamatz',
    sound: 'za',
    hebrew: 'זָ',
  },
  'זַ': {
    name: 'zayin with patach',
    sound: 'za',
    hebrew: 'זַ',
  },
  'זֶ': {
    name: 'zayin with segol',
    sound: 'ze',
    hebrew: 'זֶ',
  },
  'זֵ': {
    name: 'zayin with tzere',
    sound: 'ze',
    hebrew: 'זֵ',
  },
  'זִ': {
    name: 'zayin with chirik',
    sound: 'zi',
    hebrew: 'זִ',
  },
  'זֹ': {
    name: 'zayin with cholam',
    sound: 'zo',
    hebrew: 'זֹ',
  },
  'זֻ': {
    name: 'zayin with kubutz',
    sound: 'zu',
    hebrew: 'זֻ',
  },
  'זְ': {
    name: 'zayin with sheva',
    sound: 'z',
    hebrew: 'זְ',
  },
  // chet
  'חָ': {
    name: 'chet with kamatz',
    sound: 'cha',
    hebrew: 'חָ',
  },
  'חַ': {
    name: 'chet with patach',
    sound: 'cha',
    hebrew: 'חַ',
  },
  'חֶ': {
    name: 'chet with segol',
    sound: 'che',
    hebrew: 'חֶ',
  },
  'חֵ': {
    name: 'chet with tzere',
    sound: 'che',
    hebrew: 'חֵ',
  },
  'חִ': {
    name: 'chet with chirik',
    sound: 'chi',
    hebrew: 'חִ',
  },
  'חֹ': {
    name: 'chet with cholam',
    sound: 'cho',
    hebrew: 'חֹ',
  },
  'חֻ': {
    name: 'chet with kubutz',
    sound: 'chu',
    hebrew: 'חֻ',
  },
  'חְ': {
    name: 'chet with sheva',
    sound: 'ch',
    hebrew: 'חְ',
  },
  // tet
  'טָ': {
    name: 'tet with kamatz',
    sound: 'ta',
    hebrew: 'טָ',
  },
  'טַ': {
    name: 'tet with patach',
    sound: 'ta',
    hebrew: 'טַ',
  },
  'טֶ': {
    name: 'tet with segol',
    sound: 'te',
    hebrew: 'טֶ',
  },
  'טֵ': {
    name: 'tet with tzere',
    sound: 'te',
    hebrew: 'טֵ',
  },
  'טִ': {
    name: 'tet with chirik',
    sound: 'ti',
    hebrew: 'טִ',
  },
  'טֹ': {
    name: 'tet with cholam',
    sound: 'to',
    hebrew: 'טֹ',
  },
  'טֻ': {
    name: 'tet with kubutz',
    sound: 'tu',
    hebrew: 'טֻ',
  },
  'טְ': {
    name: 'tet with sheva',
    sound: 't',
    hebrew: 'טְ',
  },
  // yod
  'יָ': {
    name: 'yod with kamatz',
    sound: 'ya',
    hebrew: 'יָ',
  },
  'יַ': {
    name: 'yod with patach',
    sound: 'ya',
    hebrew: 'יַ',
  },
  'יֶ': {
    name: 'yod with segol',
    sound: 'ye',
    hebrew: 'יֶ',
  },
  'יֵ': {
    name: 'yod with tzere',
    sound: 'ye',
    hebrew: 'יֵ',
  },
  'יִ': {
    name: 'yod with chirik',
    sound: 'yi',
    hebrew: 'יִ',
  },
  'יֹ': {
    name: 'yod with cholam',
    sound: 'yo',
    hebrew: 'יֹ',
  },
  'יֻ': {
    name: 'yod with kubutz',
    sound: 'yu',
    hebrew: 'יֻ',
  },
  'יְ': {
    name: 'yod with sheva',
    sound: 'y',
    hebrew: 'יְ',
  },
  // kaf
  'כָּ': {
    name: 'kaf with kamatz',
    sound: 'ka',
    hebrew: 'כָּ',
  },
  'כַּ': {
    name: 'kaf with patach',
    sound: 'ka',
    hebrew: 'כַּ',
  },
  'כֶּ': {
    name: 'kaf with segol',
    sound: 'ke',
    hebrew: 'כֶּ',
  },
  'כֵּ': {
    name: 'kaf with tzere',
    sound: 'ke',
    hebrew: 'כֵּ',
  },
  'כִּ': {
    name: 'kaf with chirik',
    sound: 'ki',
    hebrew: 'כִּ',
  },
  'כֹּ': {
    name: 'kaf with cholam',
    sound: 'ko',
    hebrew: 'כֹּ',
  },
  'כֻּ': {
    name: 'kaf with kubutz',
    sound: 'ku',
    hebrew: 'כֻּ',
  },
  'כְּ': {
    name: 'kaf with sheva',
    sound: 'k',
    hebrew: 'כְּ',
  },
  // kaf sofit
  'ךָּ': {
    name: 'kaf sofit with kamatz',
    sound: 'ka',
    hebrew: 'ךָּ',
  },
  'ךַּ': {
    name: 'kaf sofit with patach',
    sound: 'ka',
    hebrew: 'ךַּ',
  },
  'ךֶּ': {
    name: 'kaf sofit with segol',
    sound: 'ke',
    hebrew: 'ךֶּ',
  },
  'ךֵּ': {
    name: 'kaf sofit with tzere',
    sound: 'ke',
    hebrew: 'ךֵּ',
  },
  'ךִּ': {
    name: 'kaf sofit with chirik',
    sound: 'ki',
    hebrew: 'ךִּ',
  },
  'ךֹּ': {
    name: 'kaf sofit with cholam',
    sound: 'ko',
    hebrew: 'ךֹּ',
  },
  'ךֻּ': {
    name: 'kaf sofit with kubutz',
    sound: 'ku',
    hebrew: 'ךֻּ',
  },
  'ךְּ': {
    name: 'kaf sofit with sheva',
    sound: 'k',
    hebrew: 'ךְּ',
  },
  // Khaf (no dagesh)
  'כָ': {
    name: 'khaf (no dagesh) with kamatz',
    sound: 'kha',
    hebrew: 'כָ',
  },
  'כַ': {
    name: 'khaf (no dagesh) with patach',
    sound: 'kha',
    hebrew: 'כַ',
  },
  'כֶ': {
    name: 'khaf (no dagesh) with segol',
    sound: 'khe',
    hebrew: 'כֶ',
  },
  'כֵ': {
    name: 'khaf (no dagesh) with tzere',
    sound: 'khe',
    hebrew: 'כֵ',
  },
  'כִ': {
    name: 'khaf (no dagesh) with chirik',
    sound: 'khi',
    hebrew: 'כִ',
  },
  'כֺ': {
    name: 'khaf (no dagesh) with cholam',
    sound: 'kho',
    hebrew: 'כֺ',
  },
  'כֻ': {
    name: 'khaf (no dagesh) with kubutz',
    sound: 'khu',
    hebrew: 'כֻ',
  },
  'כְ': {
    name: 'khaf (no dagesh) with sheva',
    sound: 'kh',
    hebrew: 'כְ',
  },
  // khaf sofit (no dagesh)
  'ךָ': {
    name: 'khaf sofit (no dagesh) with kamatz',
    sound: 'akh',
    hebrew: 'ךָ',
  },
  'ךַ': {
    name: 'khaf sofit (no dagesh) with patach',
    sound: 'akh',
    hebrew: 'ךַ',
  },
  'ךֶ': {
    name: 'khaf sofit (no dagesh) with segol',
    sound: 'khe',
    hebrew: 'ךֶ',
  },
  'ךֵ': {
    name: 'khaf sofit (no dagesh) with tzere',
    sound: 'khe',
    hebrew: 'ךֵ',
  },
  'ךִ': {
    name: 'khaf sofit (no dagesh) with chirik',
    sound: 'khi',
    hebrew: 'ךִ',
  },
  'ךֺ': {
    name: 'khaf sofit (no dagesh) with cholam',
    sound: 'kho',
    hebrew: 'ךֺ',
  },
  'ךֻ': {
    name: 'khaf sofit (no dagesh) with kubutz',
    sound: 'khu',
    hebrew: 'ךֻ',
  },
  'ךְ': {
    name: 'khaf sofit (no dagesh) with sheva',
    sound: 'kh',
    hebrew: 'ךְ',
  },
  // lamed
  'לָ': {
    name: 'lamed with kamatz',
    sound: 'la',
    hebrew: 'לָ',
  },
  'לַ': {
    name: 'lamed with patach',
    sound: 'la',
    hebrew: 'לַ',
  },
  'לֶ': {
    name: 'lamed with segol',
    sound: 'le',
    hebrew: 'לֶ',
  },
  'לֵ': {
    name: 'lamed with tzere',
    sound: 'le',
    hebrew: 'לֵ',
  },
  'לִ': {
    name: 'lamed with chirik',
    sound: 'li',
    hebrew: 'לִ',
  },
  'לֹ': {
    name: 'lamed with cholam',
    sound: 'lo',
    hebrew: 'לֹ',
  },
  'לֻ': {
    name: 'lamed with kubutz',
    sound: 'lu',
    hebrew: 'לֻ',
  },
  'לְ': {
    name: 'lamed with sheva',
    sound: 'l',
    hebrew: 'לְ',
  },
  // mem
  'מָ': {
    name: 'mem with kamatz',
    sound: 'ma',
    hebrew: 'מָ',
  },
  'מַ': {
    name: 'mem with patach',
    sound: 'ma',
    hebrew: 'מַ',
  },
  'מֶ': {
    name: 'mem with segol',
    sound: 'me',
    hebrew: 'מֶ',
  },
  'מֵ': {
    name: 'mem with tzere',
    sound: 'me',
    hebrew: 'מֵ',
  },
  'מִ': {
    name: 'mem with chirik',
    sound: 'mi',
    hebrew: 'מִ',
  },
  'מֹ': {
    name: 'mem with cholam',
    sound: 'mo',
    hebrew: 'מֹ',
  },
  'מֻ': {
    name: 'mem with kubutz',
    sound: 'mu',
    hebrew: 'מֻ',
  },
  'מְ': {
    name: 'mem with sheva',
    sound: 'm',
    hebrew: 'מְ',
  },
  // mem sofit
  'םָ': {
    name: 'mem sofit with kamatz',
    sound: 'ma',
    hebrew: 'םָ',
  },
  'םַ': {
    name: 'mem sofit with patach',
    sound: 'ma',
    hebrew: 'םַ',
  },
  'םֶ': {
    name: 'mem sofit with segol',
    sound: 'me',
    hebrew: 'םֶ',
  },
  'םֵ': {
    name: 'mem sofit with tzere',
    sound: 'me',
    hebrew: 'םֵ',
  },
  'םִ': {
    name: 'mem sofit with chirik',
    sound: 'mi',
    hebrew: 'םִ',
  },
  'םֺ': {
    name: 'mem sofit with cholam',
    sound: 'mo',
    hebrew: 'םֺ',
  },
  'םֻ': {
    name: 'mem sofit with kubutz',
    sound: 'mu',
    hebrew: 'םֻ',
  },
  'םְ': {
    name: 'mem sofit with sheva',
    sound: 'm',
    hebrew: 'םְ',
  },
  // nun
  'נָ': {
    name: 'nun with kamatz',
    sound: 'na',
    hebrew: 'נָ',
  },
  'נַ': {
    name: 'nun with patach',
    sound: 'na',
    hebrew: 'נַ',
  },
  'נֶ': {
    name: 'nun with segol',
    sound: 'ne',
    hebrew: 'נֶ',
  },
  'נֵ': {
    name: 'nun with tzere',
    sound: 'ne',
    hebrew: 'נֵ',
  },
  'נִ': {
    name: 'nun with chirik',
    sound: 'ni',
    hebrew: 'נִ',
  },
  'נֹ': {
    name: 'nun with cholam',
    sound: 'no',
    hebrew: 'נֹ',
  },
  'נֻ': {
    name: 'nun with kubutz',
    sound: 'nu',
    hebrew: 'נֻ',
  },
  'נְ': {
    name: 'nun with sheva',
    sound: 'n',
    hebrew: 'נְ',
  },
  // nun sofit
  'ןָ': {
    name: 'nun sofit with kamatz',
    sound: 'na',
    hebrew: 'ןָ',
  },
  'ןַ': {
    name: 'nun sofit with patach',
    sound: 'na',
    hebrew: 'ןַ',
  },
  'ןֶ': {
    name: 'nun sofit with segol',
    sound: 'ne',
    hebrew: 'ןֶ',
  },
  'ןֵ': {
    name: 'nun sofit with tzere',
    sound: 'ne',
    hebrew: 'ןֵ',
  },
  'ןִ': {
    name: 'nun sofit with chirik',
    sound: 'ni',
    hebrew: 'ןִ',
  },
  'ןֺ': {
    name: 'nun sofit with cholam',
    sound: 'no',
    hebrew: 'ןֺ',
  },
  'ןֻ': {
    name: 'nun sofit with kubutz',
    sound: 'nu',
    hebrew: 'ןֻ',
  },
  'ןְ': {
    name: 'nun sofit with sheva',
    sound: 'n',
    hebrew: 'ןְ',
  },
  // samech
  'סָ': {
    name: 'samech with kamatz',
    sound: 'sa',
    hebrew: 'סָ',
  },
  'סַ': {
    name: 'samech with patach',
    sound: 'sa',
    hebrew: 'סַ',
  },
  'סֶ': {
    name: 'samech with segol',
    sound: 'se',
    hebrew: 'סֶ',
  },
  'סֵ': {
    name: 'samech with tzere',
    sound: 'se',
    hebrew: 'סֵ',
  },
  'סִ': {
    name: 'samech with chirik',
    sound: 'si',
    hebrew: 'סִ',
  },
  'סֹ': {
    name: 'samech with cholam',
    sound: 'so',
    hebrew: 'סֹ',
  },
  'סֻ': {
    name: 'samech with kubutz',
    sound: 'su',
    hebrew: 'סֻ',
  },
  'סְ': {
    name: 'samech with sheva',
    sound: 's',
    hebrew: 'סְ',
  },
  // ayin
  'עָ': {
    name: 'ayin with kamatz',
    sound: 'a',
    hebrew: 'עָ',
  },
  'עַ': {
    name: 'ayin with patach',
    sound: 'a',
    hebrew: 'עַ',
  },
  'עֶ': {
    name: 'ayin with segol',
    sound: 'e',
    hebrew: 'עֶ',
  },
  'עֵ': {
    name: 'ayin with tzere',
    sound: 'e',
    hebrew: 'עֵ',
  },
  'עִ': {
    name: 'ayin with chirik',
    sound: 'i',
    hebrew: 'עִ',
  },
  'עֹ': {
    name: 'ayin with cholam',
    sound: 'o',
    hebrew: 'עֹ',
  },
  'עֻ': {
    name: 'ayin with kubutz',
    sound: 'u',
    hebrew: 'עֻ',
  },
  'עְ': {
    name: 'ayin with sheva',
    sound: '',
    hebrew: 'עְ',
  },
  // pe (with dagesh)
  'פָּ': {
    name: 'pe with kamatz',
    sound: 'pa',
    hebrew: 'פָּ',
  },
  'פַּ': {
    name: 'pe with patach',
    sound: 'pa',
    hebrew: 'פַּ',
  },
  'פֶּ': {
    name: 'pe with segol',
    sound: 'pe',
    hebrew: 'פֶּ',
  },
  'פֵּ': {
    name: 'pe with tzere',
    sound: 'pe',
    hebrew: 'פֵּ',
  },
  'פִּ': {
    name: 'pe with chirik',
    sound: 'pi',
    hebrew: 'פִּ',
  },
  'פֹּ': {
    name: 'pe with cholam',
    sound: 'po',
    hebrew: 'פֹּ',
  },
  'פֻּ': {
    name: 'pe with kubutz',
    sound: 'pu',
    hebrew: 'פֻּ',
  },
  'פְּ': {
    name: 'pe with sheva',
    sound: 'p',
    hebrew: 'פְּ',
  },
  // pe (no dagesh)
  'פָ': {
    name: 'pe (no dagesh) with kamatz',
    sound: 'fa',
    hebrew: 'פָ',
  },
  'פַ': {
    name: 'pe (no dagesh) with patach',
    sound: 'fa',
    hebrew: 'פַ',
  },
  'פֶ': {
    name: 'pe (no dagesh) with segol',
    sound: 'fe',
    hebrew: 'פֶ',
  },
  'פֵ': {
    name: 'pe (no dagesh) with tzere',
    sound: 'fe',
    hebrew: 'פֵ',
  },
  'פִ': {
    name: 'pe (no dagesh) with chirik',
    sound: 'fi',
    hebrew: 'פִ',
  },
  'פֺ': {
    name: 'pe (no dagesh) with cholam',
    sound: 'fo',
    hebrew: 'פֺ',
  },
  'פֻ': {
    name: 'pe (no dagesh) with kubutz',
    sound: 'fu',
    hebrew: 'פֻ',
  },
  'פְ': {
    name: 'pe (no dagesh) with sheva',
    sound: 'f',
    hebrew: 'פְ',
  },
  // pe sofit (with dagesh)
  'ףָּ': {
    name: 'pe sofit with kamatz',
    sound: 'pa',
    hebrew: 'ףָּ',
  },
  'ףַּ': {
    name: 'pe sofit with patach',
    sound: 'pa',
    hebrew: 'ףַּ',
  },
  'ףֶּ': {
    name: 'pe sofit with segol',
    sound: 'pe',
    hebrew: 'ףֶּ',
  },
  'ףֵּ': {
    name: 'pe sofit with tzere',
    sound: 'pe',
    hebrew: 'ףֵּ',
  },
  'ףִּ': {
    name: 'pe sofit with chirik',
    sound: 'pi',
    hebrew: 'ףִּ',
  },
  'ףֹּ': {
    name: 'pe sofit with cholam',
    sound: 'po',
    hebrew: 'ףֹּ',
  },
  'ףֻּ': {
    name: 'pe sofit with kubutz',
    sound: 'pu',
    hebrew: 'ףֻּ',
  },
  'ףְּ': {
    name: 'pe sofit with sheva',
    sound: 'p',
    hebrew: 'ףְּ',
  },
  // pe sofit (no dagesh)
  'ףָ': {
    name: 'pe sofit (no dagesh) with kamatz',
    sound: 'fa',
    hebrew: 'ףָ',
  },
  'ףַ': {
    name: 'pe sofit (no dagesh) with patach',
    sound: 'fa',
    hebrew: 'ףַ',
  },
  'ףֶ': {
    name: 'pe sofit (no dagesh) with segol',
    sound: 'fe',
    hebrew: 'ףֶ',
  },
  'ףֵ': {
    name: 'pe sofit (no dagesh) with tzere',
    sound: 'fe',
    hebrew: 'ףֵ',
  },
  'ףִ': {
    name: 'pe sofit (no dagesh) with chirik',
    sound: 'fi',
    hebrew: 'ףִ',
  },
  'ףֺ': {
    name: 'pe sofit (no dagesh) with cholam',
    sound: 'fo',
    hebrew: 'ףֺ',
  },
  'ףֻ': {
    name: 'pe sofit (no dagesh) with kubutz',
    sound: 'fu',
    hebrew: 'ףֻ',
  },
  'ףְ': {
    name: 'pe sofit (no dagesh) with sheva',
    sound: 'f',
    hebrew: 'ףְ',
  },
  // tsadi
  'צָ': {
    name: 'tsadi with kamatz',
    sound: 'tsa',
    hebrew: 'צָ',
  },
  'צַ': {
    name: 'tsadi with patach',
    sound: 'tsa',
    hebrew: 'צַ',
  },
  'צֶ': {
    name: 'tsadi with segol',
    sound: 'tse',
    hebrew: 'צֶ',
  },
  'צֵ': {
    name: 'tsadi with tzere',
    sound: 'tse',
    hebrew: 'צֵ',
  },
  'צִ': {
    name: 'tsadi with chirik',
    sound: 'tsi',
    hebrew: 'צִ',
  },
  'צֹ': {
    name: 'tsadi with cholam',
    sound: 'tso',
    hebrew: 'צֹ',
  },
  'צֻ': {
    name: 'tsadi with kubutz',
    sound: 'tsu',
    hebrew: 'צֻ',
  },
  'צְ': {
    name: 'tsadi with sheva',
    sound: 'ts',
    hebrew: 'צְ',
  },
  // tsadi sofit
  'ץָ': {
    name: 'tsadi sofit with kamatz',
    sound: 'tsa',
    hebrew: 'ץָ',
  },
  'ץַ': {
    name: 'tsadi sofit with patach',
    sound: 'tsa',
    hebrew: 'ץַ',
  },
  'ץֶ': {
    name: 'tsadi sofit with segol',
    sound: 'tse',
    hebrew: 'ץֶ',
  },
  'ץֵ': {
    name: 'tsadi sofit with tzere',
    sound: 'tse',
    hebrew: 'ץֵ',
  },
  'ץִ': {
    name: 'tsadi sofit with chirik',
    sound: 'tsi',
    hebrew: 'ץִ',
  },
  'ץֺ': {
    name: 'tsadi sofit with cholam',
    sound: 'tso',
    hebrew: 'ץֺ',
  },
  'ץֻ': {
    name: 'tsadi sofit with kubutz',
    sound: 'tsu',
    hebrew: 'ץֻ',
  },
  'ץְ': {
    name: 'tsadi sofit with sheva',
    sound: 'ts',
    hebrew: 'ץְ',
  },
  // quf
  'קָ': {
    name: 'quf with kamatz',
    sound: 'ka',
    hebrew: 'קָ',
  },
  'קַ': {
    name: 'quf with patach',
    sound: 'ka',
    hebrew: 'קַ',
  },
  'קֶ': {
    name: 'quf with segol',
    sound: 'ke',
    hebrew: 'קֶ',
  },
  'קֵ': {
    name: 'quf with tzere',
    sound: 'ke',
    hebrew: 'קֵ',
  },
  'קִ': {
    name: 'quf with chirik',
    sound: 'ki',
    hebrew: 'קִ',
  },
  'קֹ': {
    name: 'quf with cholam',
    sound: 'ko',
    hebrew: 'קֹ',
  },
  'קֻ': {
    name: 'quf with kubutz',
    sound: 'ku',
    hebrew: 'קֻ',
  },
  'קְ': {
    name: 'quf with sheva',
    sound: 'k',
    hebrew: 'קְ',
  },
  // resh
  'רָ': {
    name: 'resh with kamatz',
    sound: 'ra',
    hebrew: 'רָ',
  },
  'רַ': {
    name: 'resh with patach',
    sound: 'ra',
    hebrew: 'רַ',
  },
  'רֶ': {
    name: 'resh with segol',
    sound: 're',
    hebrew: 'רֶ',
  },
  'רֵ': {
    name: 'resh with tzere',
    sound: 're',
    hebrew: 'רֵ',
  },
  'רִ': {
    name: 'resh with chirik',
    sound: 'ri',
    hebrew: 'רִ',
  },
  'רֹ': {
    name: 'resh with cholam',
    sound: 'ro',
    hebrew: 'רֹ',
  },
  'רֻ': {
    name: 'resh with kubutz',
    sound: 'ru',
    hebrew: 'רֻ',
  },
  'רְ': {
    name: 'resh with sheva',
    sound: 'r',
    hebrew: 'רְ',
  },
  // shin
  'שָׁ': {
    name: 'shin with kamatz',
    sound: 'sha',
    hebrew: 'שָׁ',
  },
  'שַׁ': {
    name: 'shin with patach',
    sound: 'sha',
    hebrew: 'שַׁ',
  },
  'שֶׁ': {
    name: 'shin with segol',
    sound: 'she',
    hebrew: 'שֶׁ',
  },
  'שֵׁ': {
    name: 'shin with tzere',
    sound: 'she',
    hebrew: 'שֵׁ',
  },
  'שִׁ': {
    name: 'shin with chirik',
    sound: 'shi',
    hebrew: 'שִׁ',
  },
  'שֹׁ': {
    name: 'shin with cholam',
    sound: 'sho',
    hebrew: 'שֹׁ',
  },
  'שֻׁ': {
    name: 'shin with kubutz',
    sound: 'shu',
    hebrew: 'שֻׁ',
  },
  'שְׁ': {
    name: 'shin with sheva',
    sound: 'sh',
    hebrew: 'שְׁ',
  },
  // sin
  'שָׂ': {
    name: 'sin with kamatz',
    sound: 'sa',
    hebrew: 'שָׂ',
  },
  'שַׂ': {
    name: 'sin with patach',
    sound: 'sa',
    hebrew: 'שַׂ',
  },
  'שֶׂ': {
    name: 'sin with segol',
    sound: 'se',
    hebrew: 'שֶׂ',
  },
  'שֵׂ': {
    name: 'sin with tzere',
    sound: 'se',
    hebrew: 'שֵׂ',
  },
  'שִׂ': {
    name: 'sin with chirik',
    sound: 'si',
    hebrew: 'שִׂ',
  },
  'שֹׂ': {
    name: 'sin with cholam',
    sound: 'so',
    hebrew: 'שֹׂ',
  },
  'שֻׂ': {
    name: 'sin with kubutz',
    sound: 'su',
    hebrew: 'שֻׂ',
  },
  'שְׂ': {
    name: 'sin with sheva',
    sound: 's',
    hebrew: 'שְׂ',
  },
  // tav
  'תָּ': {
    name: 'tav with kamatz',
    sound: 'ta',
    hebrew: 'תָּ',
  },
  'תַּ': {
    name: 'tav with patach',
    sound: 'ta',
    hebrew: 'תַּ',
  },
  'תֶּ': {
    name: 'tav with segol',
    sound: 'te',
    hebrew: 'תֶּ',
  },
  'תֵּ': {
    name: 'tav with tzere',
    sound: 'te',
    hebrew: 'תֵּ',
  },
  'תִּ': {
    name: 'tav with chirik',
    sound: 'ti',
    hebrew: 'תִּ',
  },
  'תֹּ': {
    name: 'tav with cholam',
    sound: 'to',
    hebrew: 'תֹּ',
  },
  'תֻּ': {
    name: 'tav with kubutz',
    sound: 'tu',
    hebrew: 'תֻּ',
  },
  'תְּ': {
    name: 'tav with sheva',
    sound: 't',
    hebrew: 'תְּ',
  },
};