import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { FlashCardsPage } from './pages/flash-cards';
import { ShmaPage } from './pages/shma';
import { TorahBrachotPage } from './pages/torah-brachot';
import { Layout } from './components/layout';
import { HaftarahBrachotPage } from './pages/haftarah-brachot';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <FlashCardsPage />,
      },
      {
        path: "/flash-cards",
        element: <FlashCardsPage />
      },
      {
        path: "/shma",
        element: <ShmaPage />
      },
      {
        path: "/torah-brachot",
        element: <TorahBrachotPage />
      },
      {
        path: "/haftarah-brachot",
        element: <HaftarahBrachotPage />
      }
    ]
  },
]);


const App: React.FC = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
