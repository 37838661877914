import a from "./a.mp3";
import e from "./e.mp3";
import i from "./i.mp3";
import o from "./o.mp3";
import u from "./u.mp3";
import b from "./b.mp3";
import ba from "./ba.mp3";
import be from "./be.mp3";
import bi from "./bi.mp3";
import bo from "./bo.mp3";
import bu from "./bu.mp3";
import v from "./v.mp3";
import va from "./va.mp3";
import ve from "./ve.mp3";
import vi from "./vi.mp3";
import vo from "./vo.mp3";
import vu from "./vu.mp3";
import g from "./g.mp3";
import ga from "./ga.mp3";
import ge from "./ge.mp3";
import gi from "./gi.mp3";
import go from "./go.mp3";
import gu from "./gu.mp3";
import d from "./d.mp3";
import da from "./da.mp3";
import de from "./de.mp3";
import di from "./di.mp3";
import do_ from "./do.mp3";
import du from "./du.mp3";
import h from "./h.mp3";
import ha from "./ha.mp3";
import he from "./he.mp3";
import hi from "./hi.mp3";
import ho from "./ho.mp3";
import hu from "./hu.mp3";
import z from "./z.mp3";
import za from "./za.mp3";
import ze from "./ze.mp3";
import zi from "./zi.mp3";
import zo from "./zo.mp3";
import zu from "./zu.mp3";
import ch from "./ch.mp3";
import cha from "./cha.mp3";
import che from "./che.mp3";
import chi from "./chi.mp3";
import cho from "./cho.mp3";
import chu from "./chu.mp3";
import t from "./t.mp3";
import ta from "./ta.mp3";
import te from "./te.mp3";  
import ti from "./ti.mp3";
import to from "./to.mp3";
import tu from "./tu.mp3";
import y from "./y.mp3";
import ya from "./ya.mp3";
import ye from "./ye.mp3";
import yi from "./yi.mp3";
import yo from "./yo.mp3";
import yu from "./yu.mp3";
import k from "./k.mp3";
import ka from "./ka.mp3";
import ke from "./ke.mp3";
import ki from "./ki.mp3";
import ko from "./ko.mp3";
import ku from "./ku.mp3";
import kh from "./kh.mp3";
import kha from "./kha.mp3";
import khe from "./khe.mp3";
import khi from "./khi.mp3";
import kho from "./kho.mp3";
import khu from "./khu.mp3";
import l from "./l.mp3";
import la from "./la.mp3";
import le from "./le.mp3";
import li from "./li.mp3";
import lo from "./lo.mp3";
import lu from "./lu.mp3";
import m from "./m.mp3";
import ma from "./ma.mp3";
import me from "./me.mp3";
import mi from "./mi.mp3";
import mo from "./mo.mp3";
import mu from "./mu.mp3";
import n from "./n.mp3";
import na from "./na.mp3";
import ne from "./ne.mp3";
import ni from "./ni.mp3";
import no from "./no.mp3";
import nu from "./nu.mp3";
import s from "./s.mp3";
import sa from "./sa.mp3";
import se from "./se.mp3";
import si from "./si.mp3";
import so from "./so.mp3";
import su from "./su.mp3";
import p from "./p.mp3";
import pa from "./pa.mp3";
import pe from "./pe.mp3";
import pi from "./pi.mp3";
import po from "./po.mp3";
import pu from "./pu.mp3";
import f from "./f.mp3";
import fa from "./fa.mp3";
import fe from "./fe.mp3";
import fi from "./fi.mp3";
import fo from "./fo.mp3";
import fu from "./fu.mp3";
import ts from "./ts.mp3";
import tsa from "./tsa.mp3";
import tse from "./tse.mp3";
import tsi from "./tsi.mp3";
import tso from "./tso.mp3";
import tsu from "./tsu.mp3";
import r from "./r.mp3";
import ra from "./ra.mp3";
import re from "./re.mp3";
import ri from "./ri.mp3";
import ro from "./ro.mp3";
import ru from "./ru.mp3";
import sh from "./sh.mp3";
import sha from "./sha.mp3";
import she from "./she.mp3";
import shi from "./shi.mp3";
import sho from "./sho.mp3";
import shu from "./shu.mp3";
import ach from "./ach.mp3";
import akh from "./akh.mp3";

export const sounds: Record<string, string> = {
  a,
  e,
  i,
  o,
  u,
  b,
  ba,
  be,
  bi,
  bo,
  bu,
  v,
  va,
  ve,
  vi,
  vo,
  vu,
  g,
  ga,
  ge,
  gi,
  go,
  gu,
  d,
  da,
  de,
  di,
  do_,
  du,
  h,
  ha,
  he,
  hi,
  ho,
  hu,
  z,
  za,
  ze,
  zi,
  zo,
  zu,
  ch,
  cha,
  che,
  chi,
  cho,
  chu,
  t,
  ta,
  te,
  ti,
  to,
  tu,
  y,
  ya,
  ye,
  yi,
  yo,
  yu,
  k,
  ka,
  ke,
  ki,
  ko,
  ku,
  kh,
  kha,
  khe,
  khi,
  kho,
  khu,
  l,
  la,
  le,
  li,
  lo,
  lu,
  m,
  ma,
  me,
  mi,
  mo,
  mu,
  n,
  na,
  ne,
  ni,
  no,
  nu,
  s,
  sa,
  se,
  si,
  so,
  su,
  p,
  pa,
  pe,
  pi,
  po,
  pu,
  f,
  fa,
  fe,
  fi,
  fo,
  fu,
  ts,
  tsa,
  tse,
  tsi,
  tso,
  tsu,
  r,
  ra,
  re,
  ri,
  ro,
  ru,
  sh,
  sha,
  she,
  shi,
  sho,
  shu,
  ach,
  akh,
};